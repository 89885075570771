import Service from "../Service";

const resource = "packingarmedpallet/";

export default {

    save(abt, requestID){
        return Service.post(resource + "save", abt,{
            params: { requestID: requestID }
        });
    },

    pagination(parameters, requestID) {        
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },

    list(abt, requestID) {        
        return Service.post(resource + "list", abt, {
            params: { requestID: requestID },
        });
    },

    ListDetailPallet(abt, requestID) {        
        return Service.post(resource + "ListDetailPallet", abt, {
            params: { requestID: requestID },
        });
    },

    GetQr(pap, requestID) {
        return Service.post(resource + "GetQr", pap, {
          params: { requestID: requestID },
        });
    },
    getPalletSend(obj, requestID) {
        return Service.post(resource + "getPalletSend", obj, {
            params: { requestID: requestID },
        });
    },
    createPalletChamber(obj, requestID) {
        return Service.post(resource + "createPalletChamber", obj, {
            params: { requestID: requestID },
        });
    },
    generateCod(obj, requestID) {
        return Service.post(resource + "generateCod",{},{
            params: { requestID: requestID },
        });
    },
    getCampaignArea(obj, requestID) {
        return Service.post(resource + "getcampaignarea",obj,{
            params: { requestID: requestID },
        });
    },
    getCampaignsArea(obj, requestID) {
        return Service.post(resource + "getcampaignsarea",obj,{
            params: { requestID: requestID },
        });
    },
}