<template>
    <div>
        <v-card>
            <v-toolbar dense>
                <v-toolbar-title>
                    <v-row style="margin-top: 10px">
                        <v-col cols="12" style="display: inline-flex;">
                            <v-chip
                                style="margin: 0px"
                                small
                                :color="'info'"
                            >
                                {{ "Ingreso QR" }} 
                            </v-chip>
                        </v-col>
                    </v-row>
                </v-toolbar-title>
            </v-toolbar>
        </v-card>
        <v-card dense>
            <v-row justify="center" style="margin:auto">
                <v-col
                    align="center"
                    cols="12"
                    lg="12"
                    md="12"
                >   
                    <s-scanner-qr
                        :config="configScann"
                        return-object 
                        @onValue="onValue($event)"
                        :readonly="false"
                        :autofocus="false"
                        :clearInput="clearInput"
                        >
                    </s-scanner-qr>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-data-table
                        dense
                        :items="itemsPalletDetail"
                        :headers="HeaderPalletDetail"
                        :items-per-page="-1"                        
                        disable-sort    
                        show-select      
                        item-key="CidID"                        
                        v-model="itemPalletContent"               
                        hide-default-footer>
                        <template v-slot:item.TotalBox="{ item }">
                            <s-text v-model="item.TotalBox" type="number" :min="0" @input="UpdateWeight(item)"  >

                            </s-text>
                        </template>
                        <template v-slot:item.IsObserved="{ item }">
                            <tr v-if="item.IsObserved == 1">
                               <v-chip  x-small class="ma-2" color="red" text-color="white" @click="CargarObservacion(item)">
                                    SI
                               </v-chip>
                            </tr>
                            <tr v-if="item.IsObserved != 1">
                               <v-chip  x-small class="ma-2" color="green" style="font-size: 10px;font-weight: bold;" text-color="white">
                                    NO
                               </v-chip>
                            </tr>
                        </template>
                        
                    </v-data-table>
                </v-col>
            </v-row>
            <v-row justify="center" style="margin:auto">
                <v-col  cols="6" lg="6" md="6">
                    <s-select-definition
                        label = "Motivo de Reempaque"
                        :def="1447"
                        v-model="itemHead.TypeMotiveRepacking"
                        clearable   
                    >

                    </s-select-definition>
                </v-col>
            </v-row>
            <v-row justify="center" style="margin:auto">
                <v-col  cols="3" lg="3" md="3">
                    <v-btn small block left color="primary" dark @click="saveReempaque()" hide-details>
                        <v-icon left small>fas fa-save</v-icon> Guardar Reempaque
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
				<v-col lg="12" class="pb-1">
					<s-crud
						:filter="filter"
						:config="config"
						searchInput
						title="Listado de pallets en Reempaque"
						no-full
						height="auto"
						dense
						ref="gridIncomeRepacking"
					>
                    <!-- @rowSelected="rowSelected($event)" -->
						<!-- Filtros -->
						<template v-slot:filter>
							<v-container>
								<v-row justify="center" class="s-col-form">
									
									<v-col justify="center" lg="3" cols="12"
										><s-date
											label="Fecha Inicio"
											v-model="filter.DateBegin"
										></s-date>
									</v-col>
									<v-col lg="3" cols="12"
										><s-date
											label="Fecha Fin"
											v-model="filter.DateEnd"
										></s-date>
									</v-col>
								</v-row>
								
								<v-row class="s-col-form">
									
								</v-row>
							</v-container>
						</template>

						
						<template v-slot:repackaging="{ row }">
							<v-btn
								x-small
								fab
								color="info"
								@click="sendRepackaging(row)">
								<v-icon
                                    style="font-size: 16px !important">
                                     fa-box-open
                                </v-icon>
							</v-btn>
						</template>

						<template v-slot:details="{ row }">
							<v-btn
								x-small
								fab
								style="margin: 6px 6px 6px 6px; background-color: #dd3b62; color: white;"
								@click="deleteParihuela(row)">
								<v-icon
                                    style="font-size: 16px !important">
                                    fas fa-times
                                </v-icon>
							</v-btn>
						</template>
                        
						
					</s-crud>
				</v-col>
			</v-row>
                  
        </v-card>
    
        <v-dialog
            v-if="ModalRepackaging"
            v-model="ModalRepackaging"
            persistent
            width="900"
        >
            <income-pallet-repackaging
                :FprID ="FprID"
                :PapID ="PapID"
                :DetailsTraceabilityID ="DetailsTraceabilityID"
                :NumberBoxes ="NumberBoxes"
                :WeightNetBoxes = "WeightNetBoxes"
                :PalletSelect ="PalletSelect"
                @CloseRepackaging="CloseRepackaging()"
            >

            </income-pallet-repackaging>
        </v-dialog>
        <v-dialog 
            v-model="dialogObservation"
            v-if="dialogObservation"
            max-width="350"
            >
            <v-card outlined>
                <!-- <v-card-title class="title font-weight-light" >     -->
                <s-toolbar color="primary" dark close @close="dialogObservation = false" label="Descripcion de Observacion">

                </s-toolbar>
                <!-- </v-card-title> -->
                <v-card-text>
                    <v-row class="s-col-form">
                        <v-col>
                            <v-row>
                                <v-col>
                                    <h2>Observacion: </h2>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <h3>{{ this.ObservedString }}</h3>
                                </v-col>                                    
                            </v-row>
                        </v-col>
                    </v-row>
                    
                    
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
    import sScannerQr from '../../../../components/Utils/sScannerQr.vue';

    import _sFrzIncomePalletRepackingService from "@/services/FrozenProduction/FrzIncomePalletRepacking.js";
    import incomePalletRepackaging from "./cFrzIncomePalletRepackaging.vue"

    import _sFrzCameraIncomePallet from "@/services/FrozenProduction/FrzCameraIncomePallet.js";
    export default{
        components: { sScannerQr, incomePalletRepackaging},
        data:() => ({
            openModalIncomeTunnel :false,
            clearInput:false,
            configScann: {}, 

            filter: {
                DateBegin: null,
                DateEnd: null,                    
            },
            
            config: {
				model: {
					PapID: "ID",
                    DateBegin: "date",
                    DateEnd: "date",
                    SecStatus: "SecStatus",
                    details:"",
                    repackaging:""
				},
				service: _sFrzIncomePalletRepackingService,
				headers: [								
                    // { text: "Accion", value: "repackaging", width: 20 },
					{ text: "Fecha", value: "DateIncome", width: 60 },
					{ text: "Hora", value: "HourIncome", width: 60 },
                    { text: "ID Pallet", value: "PalletGroupID", width: 20 },
                    // { text: "ID Proveedor", value: "RcfIDs", width: 20 },
                    { text: "ID Trazabilidad", value: "DetailsTraceabilityID", width: 20 },
					{ text: "Cliente", value: "CumNombre", width: 60 },
                    { text: "Marca", value: "CbdBrans", width: 60 },
                    { text: "Variedad", value: "VrtDescription", width: 60 },
                    { text: "Tipo de Corte", value: "TypeCutName", width: 60 },
                    { text: "Presentación", value: "TypePackingName", width: 60 },
					{ text: "N° Cajas", value: "NumberBoxes", width: 60 },
					{ text: "Peso Neto", value: "WeightNetBoxes", width: 60 },		
                    { text: "Tipo de Congelamiento", value: "CecDescription", width: 60 },
                    { text: "Motivo de reempaque", value: "TypeMotiveRepackingName", width: 60 },				
                    { text: "Detalle", value: "details", width: 20 },		
				],
			},
            ModalRepackaging: false,
            itemHead : {},
            PalletSelect : {},
            itemsPalletDetail: [],
            HeaderPalletDetail: [
                { text:"Grupo ID", value :"PalletGroupID"},
                { text: "Trazabilidad", value: "TraceabilityID"},
                { text: "Lote", value: "Lote"},
                { text: "Cliente", value: "CumNombre"},
                { text: "Marca", value: "BransName"},
                { text: "Cultivo", value: "TypeCultiveName"},
                { text: "Variedad", value: "VrtDescription"},
                { text: "Tipo de Corte", value: "TypeCutName"},
                { text: "Presentacion", value: "TypePresentationName"},
                { text: "N° Cajas", value: "TotalBox",  width: "100px" },
                { text: "Peso Neto", value: "TotalBagWeight"},
                // { text: "Condicion", value: "IsObservedString"},
                { text: "T. Congelamiento", value: "CecDescription"},
                { text: "Localizacion", value: "PalletLocation" },
                { text: "E. Final", value: "StateFinalString" },
                // { text: "E. Final", value: "StateFinal" },
                { text: "Observado", value: "IsObserved" },
                // { text: "Guardar Rol", value: "saveRoll", align: "center"}

            ], 
            itemPalletContent: [],
            PalletGroupID: 0,
            Validate: false,
            ObservedString: "",
            dialogObservation: false,
        }),
        
        
        methods:{
            
            CargarObservacion(item)
            {
                
                this.ObservedString = item.Observation;
                this.dialogObservation = true;

            },
            closedialogObservation()
            {
                this.ObservedString = "";
                this.dialogObservation = false;
            },
            onValue(val)
            {               

                this.PalletGroupID = val;
                if (val == "Sin datos" || isNaN(val)) {
					this.$fun.alert("El QR Leido no contiene datos", "warning");
				} else {

                    _sFrzCameraIncomePallet.getPalletByGroupID({PalletGroupID: val}, this.$fun.getUserID())
                    .then((r) => {
                        if(r.status == 200)
                        {
                            if(r.data.length == 0){
                                this.$fun.alert("Pallet no existe", "warning");
                                return;
                            }
                            
                            r.data[0].palletDetailCEs.forEach(element => {     
                                element.Lote = r.data[0].Lote;
                                element.NumberBoxesOld = element.TotalBox;  
                                if(element.TotalBox > 0 && element.TotalBagWeight > 0)
                                {
                                    this.Validate = true;
                                }
                                element.WeightNetBoxesOld = parseFloat(element.TotalBagWeight);  
                                element.TotalBox = element.TotalBox;         
                                element.TotalBagWeight = element.TotalBagWeight;  
                                element.CecDescription = r.data[0].CecDescription;   
                                element.BransName = r.data[0].BransName; 
                                element.PalletLocation = r.data[0].PalletLocation;   
                                element.PalletGroupID = r.data[0].PalletGroupID;  
                                element.CumNombre = r.data[0].CumNombre; 
                                element.CumID = r.data[0].CumID;      
                                element.CmaID = r.data[0].CmaID; 
                                element.TypeCut = r.data[0].TypeCut;    
                                element.CbdID = r.data[0].CbdID;    
                                element.SideID = r.data[0].SideID; 
                                element.NumberLanes = r.data[0].NumberLanes;     
                                element.NumberLevels = r.data[0].NumberLevels;   
                                element.PositionsLeve = r.data[0].PositionsLeve;    
                                element.CdtID = r.data[0].CdtID;   
                                element.TypeVariant = r.data[0].TypeVariant;    
                                element.TypeProduction = r.data[0].TypeProduction; 
                                // element.StateFinal = r.data[0].StateFinal; 
                                this.itemsPalletDetail.push(element);                    
                            });  
                            
                        }
                    });
                }                
            },
            saveReempaque()
            {
                if(this.itemHead.TypeMotiveRepacking == undefined){
                    this.$fun.alert('Seleccion motivo de reempaque','warning');
                    return
                }

                if(this.itemPalletContent.length == 0){
                    this.$fun.alert('No tiene Detalle el Pallet','warning');
                    return
                }

                

                
                let item = {};
                item.TypeMotiveRepacking = this.itemHead.TypeMotiveRepacking;
                item.PalletGroupID = this.PalletGroupID;
                item.UsrCreateID = this.$fun.getUserID();
                item.UsrUpdateID = this.$fun.getUserID();                    
                            
                // _sFrzIncomePalletRepackingService.save(item, this.$fun.getUserID())
                //     .then( r => {
                //         if(r.status == 200)
                //         {

                this.itemPalletContent.forEach(item=>{
                    item.TypeMotiveRepacking = this.itemHead.TypeMotiveRepacking;
                })

                if(!this.Validate)
                {
                    this.$fun.alert("Algun valor de cajas debe ser incorrecto ", "warning");
                    return; 
                }
                this.$fun.alert("Deseas enviar Pallet a Reempaque ?", "question")
                .then(res => {
                    if (res.value) {
                            _sFrzIncomePalletRepackingService.saveDetail(this.itemPalletContent, this.$fun.getUserID())
                            .then( r => {
                                if(r.status == 200)
                                {
                                    this.itemsPalletDetail = [];
                                    this.$fun.alert("Pallet ingresado correctamente", "success");
                                    this.$refs.gridIncomeRepacking.refresh();
                                }                               
                            });   
                        }
                    });                         
                    //     }
                    // });
            },
            deleteParihuela(item){
                let val = {
                    FprID : item.FprID,
                    UsrUpdateID : this.$fun.getUserID()
                }

                // console.log('data',val);
                // return
                this.$fun.alert("Esta de seguro de eliminar ?", "question")
                .then(res => {
                    if (res.value) {
                        _sFrzIncomePalletRepackingService
                        .update(val, this.$fun.getUserID())
                        .then(r => {
                            if (r.status == 200) {
                                this.$fun.alert("Pallet eliminado correctamente", "success");
                                this.$refs.gridIncomeRepacking.refresh();
                            }
                        });
                    }
                });


            },
            sendRepackaging(item){
              
                this.ModalRepackaging = true
                this.FprID = item.FprID;
                this.PapID = item.PapID;
                this.DetailsTraceabilityID = item.DetailsTraceabilityID;
                this.NumberBoxes = item.NumberBoxes
                this.WeightNetBoxes = item.WeightNetBoxes
                this.PalletSelect = item;

            },
            CloseRepackaging(){
                this.ModalRepackaging = false
                this.$refs.gridIncomeRepacking.refresh();
            },
            UpdateWeight(item){
                
                if(parseInt(item.TotalBox) > item.NumberBoxesOld)
                {
                    this.Validate = false;
                    this.$fun.alert("El numero de cajas no puede ser mayor a " + item.NumberBoxesOld, "error");
                    return;
                }
                if(parseInt(item.TotalBox) == 0 || parseInt(item.TotalBox) == undefined)
                {
                    this.Validate = false;
                    this.$fun.alert("Ingrese numero de caja valido " , "error");
                    return;
                }
                this.Validate = true;
                item.TotalBagWeight = (item.WeightNetBoxesOld / item.NumberBoxesOld) * item.TotalBox;
            },
        },
        created()
        {
            
        }
    }
</script>