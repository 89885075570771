<template>
	<div>
		<v-card>
			<v-card-title>
                <v-row>
                    <v-col cols="8" lg="8" md="8" sm="12" class="s-col-form">
                        Reempacar Pallet N° : <b>{{ PapID }}</b>
                    </v-col>
                    <br>
                </v-row>
                <v-row>
                    <v-col></v-col>
                </v-row>
                
				
			</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="8" lg="8" md="8" sm="12" class="s-col-form">
                        <!-- ID Trazabilidad :  <b>{{ DetailsTraceabilityID }}</b>  -->
                    </v-col>
                    <v-col></v-col>
                    <!-- <v-col cols="3" lg="3" md="3" sm="12" class="s-col-form">
                        N° de veces Reempacado : <b>0</b>
                    </v-col> -->
                </v-row>
                <v-row>
                    <!-- <v-col cols="8" lg="8" md="8" sm="12" class="s-col-form">
                        Historial ID Trazabilidad : <b>0</b>
                    </v-col> -->
                    
                </v-row>
            </v-card-text> 
            
			<v-card-text>
            <v-divider></v-divider>
                <v-row style="margin:10px">
                            <v-col  align="center">
                                <v-badge color="white">
                                    <template v-slot:badge>							 
                                        <v-btn	
                                            style="margin-left: -29px;margin-top: 2px;"
                                            fab
                                            small
                                            color="white"
                                            
                                        >
                                            <v-icon 
                                                color="error" 
                                                small>
                                                fa-box-open
                                            </v-icon>
                                        </v-btn>								 
                                    </template>
                                    <v-card>								 
                                        <v-card-text>
                                            <b>ID Pallet : </b><v-chip>{{ PalletSelect.PalletGroupID }}</v-chip>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-row justify="space-around" no-gutters>
                                                <v-icon class="mdi-96px" >fa-pallet</v-icon>
                                            </v-row>
                                        </v-card-actions>
                                        <v-card-text class="text-center">
                                            <b>{{ 'N° Cajas : '+ NumberBoxes}}</b>
                                            <h4>{{ ' Peso (Kg) : '+ WeightNetBoxes}}</h4>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-row justify="space-around" no-gutters>
                                                <v-chip color="warning" >{{ DetailsTraceabilityID }}</v-chip>
                                            </v-row>
                                        </v-card-actions>																
                                    </v-card>	
                                </v-badge>
                            </v-col>
                      
                    
                </v-row>
                <v-row justify="center" style="margin:auto">
                    <v-col  cols="6" lg="6" md="6">
                        <s-select-definition
                            label = "Tipo de envio"
                            :def="1448"
                            v-model="TypeSend"
                            clearable
                        ></s-select-definition>
                    </v-col>
                </v-row>
                <!-- @change ="clickTypeSend()" -->
                <!-- <v-divider></v-divider> -->

				<v-row align="center" v-if="TypeSend == 2">
                    <v-col
                        v-for="item in tableProcess"
                        :key="item.PwpID"
                        align="center"
                    >
                        <v-card width="180" height="120">
                            <v-card-title>
                                <v-row>
                                    <v-col>
                                        {{ item.PwpID }}
                                    </v-col>
                                    <v-spacer></v-spacer>
                                    <v-radio-group
                                        v-model="TableRepackaging"
                                        style="margin-top: 5px;
                                        margin-right: -9px;"
                                        hide-details
                                    >
                                        <v-radio
                                            :value="item.PwpID"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-row>
                            </v-card-title>
                            <v-card-subtitle>
                                <v-chip color="info">{{ item.PwsDescription }}</v-chip>
                            </v-card-subtitle>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row align="center" v-if="TypeSend == 1">
                    <v-col
                        v-for="item in linesInProcess"
                        :key="item.PliID"
                        align="center"
                    >
                        <v-card width="180" height="120">
                            <v-card-title>
                                <v-row>
                                    <v-col>
                                        {{ item.PliID }}
                                    </v-col>
                                    <v-spacer></v-spacer>
                                    <v-radio-group
                                        v-model="LinesRepackaging"
                                        style="margin-top: 5px;
                                        margin-right: -9px;"
                                        hide-details
                                    >
                                        <v-radio
                                            :value="item.PliID"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-row>
                            </v-card-title>
                            <v-card-subtitle>
                                <v-chip color="info">{{ item.PklDescription }}</v-chip>
                            </v-card-subtitle>
                        </v-card>
                    </v-col>
                </v-row>
                
			</v-card-text>
			<v-card-actions>
                <!-- v-if="TableRepackaging > 0" -->
                <!-- v-if="TableRepackaging > 0 || LinesRepackaging > 0" -->
				<v-btn
                   
					color="success"
					text
					@click="sendPalletStation()"
				>
					Enviar
				</v-btn>
				<v-spacer></v-spacer>
				<v-btn
					color="orange"
					text
					@click="CloseRepackaging()"
				>
					Cancelar
				</v-btn>
			</v-card-actions>
		</v-card>
	</div>
</template>

<script>
    import _sTurnService from "@/services/FreshProduction/PrfTurnService";
    import _sServiceTableProcess from "@/services/FrozenProduction/FrzPackingStationGroupShift.js"

    import _sFrzIncomePalletRepacking from "@/services/FrozenProduction/FrzIncomePalletRepacking.js";
    import _PackingLinesInProcess from "@/services/FrozenProduction/FrzPackingLinesInProcess.js";
    import _sFrzPackingArmedPallet from "@/services/FrozenProduction/FrzPackingArmedPallet.js"

	export default {
		props: {
			FprID: {
				type: Number,
				required: false
			},
            PapID: {
				type: Number,
				required: false
			},
            DetailsTraceabilityID: {
				type: String,
				required: false
			},
            NumberBoxes:{
                type:Number,
                required:false
            },
            WeightNetBoxes:{
                type:Number,
                required: false,
            },
            PalletSelect:{
                type: Object,
                required : false,
            }
		},

		data() {
			return {
				itemTurn:[],
                tableProcess:[],
                TableRepackaging: 0,
                DetailPallet: [],
                TypeSend: 0,
                linesInProcess: [],
                LinesRepackaging: 0,

				
			}
		},

		methods: {
			CloseRepackaging() {
				this.$emit("CloseRepackaging");
			},
            TurnActive(){
            let TypeArea = 5
            _sTurnService.turnActive({TypeArea : TypeArea},this.$fun.getUserID()).then(r =>{
                    if(r.status == 200)
                    {
                        this.itemTurn = r.data;   
                        console.log(this.itemTurn[0].TrnID)
                        this.listtableProcess(this.itemTurn[0].TrnID);     
                        this.LinesInProcess(this.itemTurn[0].TrnID);                           
                    }
                });      
            },
            listtableProcess(TrnID)
            {
                _sServiceTableProcess.list({TrnID : TrnID}, this.$fun.getUserID()).then( r => {
                    if(r.status == 200)
                    {         
                        this.tableProcess = r.data;              
                        console.log("this.tableProcess->", this.tableProcess);      
                    }
                })
            },
            LinesInProcess(TrnID) 
            {             
                // if(this.itemTurn)   
                _PackingLinesInProcess.list({TrnID: TrnID}, this.$fun.getUserID())
                .then(r => {
                    if(r.status == 200){
                        this.linesInProcess = r.data;                        
                    }
                });
            },
            sendPalletStation(){
                console.log('ss',this.TypeSend);
                if(this.TypeSend == 0 || this.TypeSend == null){
                    this.$fun.alert('Seleccione tipo de envio','warning');
                    return
                }

                if(this.TypeSend == 1 && this.LinesRepackaging == 0){
                    this.$fun.alert('Seleccione faja de seleccion','warning');
                    return
                }else if (this.TypeSend == 1 && this.LinesRepackaging > 0){

                    let val = {
                        TypeSend : 1 ,
                        PliID : this.LinesRepackaging,
                        UsrCreateID : this.$fun.getUserID(),
                        FprID : this.FprID,
                        TraceabilityID : this.DetailsTraceabilityID,
                        UsrUpdateID : this.$fun.getUserID(),
                        PapID : this.PapID,
                        TrnID : this.itemTurn[0].TrnID,
                    }
                    console.log('Enviar data',this.LinesRepackaging);
                    // return
                    this.$fun.alert("Esta de seguro de enviar a reempaque ?", "question")
                    .then(res => {
                        if (res.value) {
                            _sFrzIncomePalletRepacking
                            .sendRepackaging(val, this.$fun.getUserID())
                            .then(r => {
                                if (r.status == 200) {
                                    this.$fun.alert("Enviado correctamente", "success");
                                    this.CloseRepackaging()
                                }
                            });
                        }
                    });
                }

                if(this.TypeSend == 2 && this.TableRepackaging == 0){
                    this.$fun.alert('Seleccione estacion de trabajo','warning');
                    return
                }else if (this.TypeSend == 2 && this.TableRepackaging > 0) {
                    console.log('Enviar reempaque a estacion de trabajo');
                    let val = {
                        TypeSend : 2 ,
                        PwpID : this.TableRepackaging,
                        UsrCreateID : this.$fun.getUserID(),
                        FprID : this.FprID,
                        TraceabilityID : this.DetailsTraceabilityID,
                        UsrUpdateID : this.$fun.getUserID(),
                        PapID : this.PapID,
                        TrnID : this.itemTurn[0].TrnID,
                    }
                    console.log('Enviar data',val);
                    this.$fun.alert("Esta de seguro de enviar a reempaque ?", "question")
                    .then(res => {
                        if (res.value) {
                            _sFrzIncomePalletRepacking
                            .sendRepackaging(val, this.$fun.getUserID())
                            .then(r => {
                                if (r.status == 200) {
                                    this.$fun.alert("Enviado correctamente", "success");
                                    this.CloseRepackaging()
                                }
                            });
                        }
                    });
                }

                // console.log('enviar pallet',this.itemTurn[0].TrnID)
                // let val = {
                //     // PwpID : this.TableRepackaging,
                //     UsrCreateID : this.$fun.getUserID(),
                //     FprID : this.FprID,
                //     TraceabilityID : this.DetailsTraceabilityID,
                //     UsrUpdateID : this.$fun.getUserID(),
                //     PapID : this.PapID,
                //     TrnID : this.itemTurn[0].TrnID,
                // }
                // console.log('Enviar data',val);
                // this.$fun.alert("Esta de seguro de enviar a reempaque ?", "question")
                // .then(res => {
                //     if (res.value) {
                //         _sFrzIncomePalletRepacking
                //         .sendRepackaging(val, this.$fun.getUserID())
                //         .then(r => {
                //             if (r.status == 200) {
                //                 this.$fun.alert("Enviado correctamente", "success");
                //                 this.CloseRepackaging()
                //             }
                //         });
                //     }
                // });
            },
            // clickTypeSend(){
            //     console.log('Tipo de envio',this.TypeSend);
            // }
            // ItemsPallet(){
            //     console.log('detalle pallet',this.PapID);

            //     _sFrzPackingArmedPallet.ListDetailPallet({PapID : this.PapID}, this.$fun.getUserID()).then( r => {
            //         if(r.status == 200)
            //         {         
            //             this.DetailPallet = r.data;              
            //             console.log("Detail Pallets->", this.DetailPallet);               
            //         }
            //     })
            // }


			

		},
		

		created () {
			this.TurnActive();
			

		},
		mounted()
		{
			
		},
		
	}
</script>

