import Service from "../Service";
const resource = "FrzIncomePalletRepacking/";

export default {

    pagination(parameters, requestID) {        
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },

    save(abt, requestID){
        return Service.post(resource + "save", abt,{
            params: { requestID: requestID }
        });
    },
    update(abt, requestID){
        return Service.post(resource + "update", abt,{
            params: { requestID: requestID }
        });
    },
    sendRepackaging(abt, requestID){
        return Service.post(resource + "sendRepackaging", abt,{
            params: { requestID: requestID }
        });
    },
    UpdTypeArmed(abt, requestID){
        return Service.post(resource + "UpdTypeArmed", abt,{
            params: { requestID: requestID }
        });
    },
    saveDetail(abt, requestID){
        return Service.post(resource + "savedetail", abt,{
            params: { requestID: requestID }
        });
    },
   
}


