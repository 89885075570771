import Service from "../Service";
const resource = "FrzChamberIncomePallet/";

export default {
    // IdQr(PapID, requestID) {
    //     return Service.post(resource + "IdQr", null, {
    //         // params: { PapID: parseInt(PapID), requestID: requestID },
    //         params: { requestID: requestID, PapID },
    //     });
    // },

    // save(abt, requestID){
    //     return Service.post(resource + "save", abt,{
    //         params: { requestID: requestID }
    //     });
    // },

    // pagination(parameters, requestID) {        
    //     return Service.post(resource + "pagination", parameters, {
    //         params: { requestID: requestID },
    //     });
    // },

    save(abt, requestID){
        return Service.post(resource + "save", abt,{
            params: { requestID: requestID }
        });
    },
    saveOld(abt, requestID){
        return Service.post(resource + "saveold", abt,{
            params: { requestID: requestID }
        });
    },
    getTunnelChamber(abt,requestID){
        return Service.post(resource + "getTunnelChamber", abt,{
            params: {requestID: requestID},
        });
    },

    getDataPallet(abt,requestID){
        return Service.post(resource + "getdatapallet", abt,{
            params: {requestID: requestID},
        });
    },

    
    getPalletByGroupID(abt,requestID){
        return Service.post(resource + "getpalletbygroupid", abt,{
            params: {requestID: requestID},
        });
    }
}


